import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Modal, Form, Button, Message, RadioGroup, Radio, Stack, Progress, Grid, Row, Col, Loader } from 'rsuite';
import FileUploader from 'components/elements/FileUploader';
import Textarea from 'components/elements/Textarea';
import { putFileRequest, putRequest, postRequest } from "utils/axios";
import FileUploadList from './FileUploadList';

type Props = {
  jobId: string;
  open: boolean;
  onClose: () => void;
};

const acceptedResumeFormats = [
  "application/pdf",  // PDF
  "application/msword",  // .doc (Microsoft Word)
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",  // .docx (Microsoft Word)
  "application/rtf",  // Rich Text Format
  "text/plain",  // Plain Text (.txt)
  "text/html",  // HTML
  "text/markdown",  // Markdown (.md)
  "application/vnd.oasis.opendocument.text",  // .odt (OpenDocument Text)
  "application/vnd.apple.pages",  // Apple Pages
  "application/epub+zip",  // EPUB (eBook format)
  "application/x-mobipocket-ebook"  // MOBI (eBook format)
];

export function UploadCandidateModal({ jobId, open, onClose }: Props) {
  const defautlCandidateUploadFileSize = '10485760' // 10MB in case it's not defined
  const maxCandidateUploadFileSize = parseInt(process.env.REACT_APP_MAX_CANDIDATE_UPLOAD_FILE_SIZE || defautlCandidateUploadFileSize)

  const [uploadMethod, setUploadMethod] = useState<string>('url');
  const [url, setUrl] = useState<string>('');
  const [fileList, setFileList] = useState<any>([]);
  const [dummyFileList, setDummyFileList] = useState<any>([]);
  const [text, setText] = useState<string>('');
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [uploadProgress, setUploadProgress] = useState<{ percent: number; loadedKb: number; totalKb: number }>({
    percent: 0,
    loadedKb: 0,
    totalKb: 0
  });

  const configuration = useSelector((state: RootState) => state.configuration);
  const maxFilesPermittedForUpload = configuration.features.max_files_permitted_for_upload;
  

  const handleUpload = () => {
    setUploadProgress({
      percent: 0,
      loadedKb: 0,
      totalKb: 0
    });
    setIsUploading(true);
    setMessage(null);
    setErrorMessage(null);

    if (uploadMethod === 'url' && url) {
      uploadUrl();
    } else if (uploadMethod === 'file' && fileList.length > 0) {
      uploadFiles();
    } else if (uploadMethod === 'text' && text) {
      uploadText();
    } else {
      setErrorMessage('Please provide valid input.');
      setIsUploading(false); // Set loading state to false if there's an error
    }
  };

  function handleClose() {
    resetForm();
    onClose();
  }

  const resetForm = () => {
    setUrl('');
    setFileList([]);
    setDummyFileList([]);
    setText('');
    setMessage(null);
    setErrorMessage(null);
    setUploadMethod('file');
    setIsUploading(false);
  };

  async function uploadFiles() {
    const filesToUpload = [...fileList]; // Make a copy of the current fileList
    let totalSize = 0; // Total size of all files combined
    let totalLoaded = 0; // Total loaded size across all files
  
    filesToUpload.forEach((file) => {
      if (file.status === 'selected') {
        totalSize += file.blobFile.size; // Sum up the total size of all selected files
      }
    });
  
    const uploadFile = async (file: any) => {
      try {
        updateFileStatus(file.fileKey, 'uploading');
  
        const response = await postRequest(`/imports/candidates/files/`, {
          file_name: file.name,
          content_type: file.type
        });
  
        const { id, url, content_type: contentType } = response.data;
  
        let previousLoaded = 0;
  
        const config = {
          onUploadProgress: (progressEvent: any) => {
            const loadedForThisFile = progressEvent.loaded - previousLoaded;
            previousLoaded = progressEvent.loaded;
  
            totalLoaded += loadedForThisFile; // Increment the total loaded size
  
            const percentCompleted = Math.round((totalLoaded * 90) / totalSize);
            setUploadProgress({
              percent: percentCompleted,
              loadedKb: Math.round(totalLoaded / 1024), // Convert bytes to kilobytes
              totalKb: Math.round(totalSize / 1024) // Convert bytes to kilobytes
            });
          }
        };
  
        await putFileRequest(url, file.blobFile, config.onUploadProgress, { 'Content-Type': contentType });
        updateFileStatus(file.fileKey, 'processing');

        await putRequest(`/imports/candidates/files/${id}/`, {
          job_id: jobId,
          status: 'uploaded'
        });
  
        updateFileStatus(file.fileKey, 'uploaded');
      } catch (error: any) {
        updateFileStatus(file.fileKey, 'error');
        setErrorMessage(error.response?.data?.error || 'An error occurred during the upload process.');
      }
    };
  
    for (const file of filesToUpload) {
      if (file.status === 'selected') {
        await uploadFile(file);
      }
    }
  
    setUploadProgress({
      percent: 100,
      loadedKb: Math.round(totalSize / 1024), // Set final loaded size to total size
      totalKb: Math.round(totalSize / 1024)
    });
  
    setIsUploading(false); // Set loading state to false after all uploads complete
  
    if (filesToUpload.some(file => file.status === 'uploaded')) {
      setMessage('Files are successfully processed. See your candidates in the Liked tab.');
    }
  }

  function uploadUrl() {
    const uploadRequest = {
      job_id: jobId,
      url
    };

    postRequest('/imports/candidates/urls/', uploadRequest)
      .then((response: any) => {
        setMessage('URL successfully processed. See your candidate in the Liked tab.');
      })
      .catch((error: any) => {
        setErrorMessage('Error processing URL.');
      })
      .finally(() => {
        setIsUploading(false); // Set loading state to false after upload completes
      });
  }

  function uploadText() {
    const uploadRequest = {
      job_id: jobId,
      content: text
    };

    postRequest('/imports/candidates/text/', uploadRequest)
      .then((response: any) => {
        setMessage('Text successfully processed. See your candidate in the Liked tab.');
      })
      .catch((error: any) => {
        if (error.response && error.response.data && error.response.data.error) {
          setErrorMessage('Error processing text: ' + error.response.data.error);
        } else {
          setErrorMessage('Error processing text.');
        }

      })
      .finally(() => {
        setIsUploading(false); // Set loading state to false after upload completes
      });
  }

  const updateFileStatus = (fileKey: string, status: string) => {
    setFileList((prevFileList: any) =>
      prevFileList.map((file: any) =>
        file.fileKey === fileKey ? { ...file, status } : file
      )
    );
  };

  function removeFile(fileKey: string) {
    setFileList((prevFileList: any) =>
      prevFileList.filter((file: any) => file.fileKey !== fileKey)
    );
  }

  function handleUploadMethodChange(value: any) {
    setMessage(null);
    setErrorMessage(null);
    setUploadMethod(value);
  }

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  const handleFileChange = (updatedFileList: any) => {
    setDummyFileList([]);

    const filteredFiles = updatedFileList.filter((file: any) => {
      if (file.blobFile.size > maxCandidateUploadFileSize) {
        setErrorMessage(`File "${file.blobFile.name}" exceeds the ${maxCandidateUploadFileSize}MB size limit.`);
        return false;
      }
      return acceptedResumeFormats.includes(file.blobFile.type);
    });
  
    const totalFiles = fileList.length + filteredFiles.length;
    if (totalFiles > maxFilesPermittedForUpload) {
      if (maxFilesPermittedForUpload === 1) {
        setErrorMessage(`You can only upload one file at a time. For larger bulk uploads, please upgrade your subscription.`);
      } else {
        setErrorMessage(`You can upload a maximum of ${maxFilesPermittedForUpload} files at once.`);
      }
      return;
    } else {
      setErrorMessage(null);
    }
  
    // Update fileList with both the metadata and actual file content
    const updatedFilesWithStatus = filteredFiles.map((file: any) => ({
      ...file,
      status: 'selected',
      blobFile: file.blobFile // Ensure this contains the actual file content
    }));
  
    // Prevent duplicate files from being added
    setFileList((prevFileList: any) => {
      const newFiles = updatedFilesWithStatus.filter(
        (file: any) => !prevFileList.some((f: any) => f.fileKey === file.fileKey)
      );
      return [...prevFileList, ...newFiles];
    });
  };
  

  return (
    <Modal size="lg" open={open} onClose={handleClose} onExit={handleClose}>
      <Modal.Header>
        <strong>Candidate Upload</strong>
      </Modal.Header>
      <Modal.Body>
        <p style={{fontSize: "13px", fontWeight: "400"}}>To manually add an existing candidate to the system, upload their resume here, and they will appear on the <strong>Liked</strong> tab for this job. Paid subscribers can upload multiple resume files at once.</p>
        <Form fluid>
          <Form.Group>
            <Form.ControlLabel>Select Upload Method</Form.ControlLabel>
            <Form.Control
              name="uploadMethod"
              accepter={RadioGroup}
              value={uploadMethod}
              onChange={handleUploadMethodChange}
              disabled={isUploading} // Disable during upload
            >
              <Stack direction="row">
                <Radio value="file" disabled={isUploading}>File</Radio>
                <Radio value="text" disabled={isUploading}>Text</Radio>
                { /* <Radio value="url" disabled={isUploading}>URL</Radio> */ }
              </Stack>
            </Form.Control>
          </Form.Group>

          {uploadMethod === 'url' && (
            <Form.Group>
              <Form.ControlLabel>URL</Form.ControlLabel>
              <Form.Control
                name="url"
                placeholder="Enter URL"
                value={url}
                onChange={(value: any) => setUrl(value)}
                disabled={isUploading} // Disable during upload
              />
            </Form.Group>
          )}

          {uploadMethod === 'file' && (
            <Form.Group>
              <Form.ControlLabel>File</Form.ControlLabel>
              <Form.Control
                name="file"
                accepter={FileUploader}
                autoUpload={false}
                draggable
                fileList={dummyFileList}
                fileListVisible={false}
                multiple={true}
                accept={acceptedResumeFormats.join(', ')}
                onChange={handleFileChange}
                disabled={isUploading} // Disable during upload
              />
            </Form.Group>
          )}

          {uploadMethod === 'text' && (
            <Form.Group>
              <Form.ControlLabel>Paste Text</Form.ControlLabel>
              <Form.Control
                name="text"
                accepter={Textarea}
                rows={10}
                maxLength={10000}
                placeholder="Paste resume text here"
                value={text}
                onChange={(value: any) => setText(value)}
                disabled={isUploading} // Disable during upload
              />
            </Form.Group>
          )}

          {uploadMethod === 'file' &&
            <FileUploadList fileList={fileList} onRemove={removeFile} isUploading={isUploading} />
          }
          {message && <Message type="info" showIcon closable={false}>{message}</Message>}
          {errorMessage && <Message type="error" showIcon closable={false}>{errorMessage}</Message>}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Grid fluid style={{marginTop: "5px"}}>
          <Row>
            <Col xs={18} style={{ textAlign: "left" }}>
              {isUploading && uploadMethod === 'file' &&
                <div>
                  <Progress.Line
                    percent={uploadProgress.percent}
                    strokeColor="#4caf50"
                    showInfo={true}
                  />
                  <div style={{ marginLeft: "12px", fontSize: "10px" }}>
                    {uploadProgress.loadedKb} KB / {uploadProgress.totalKb} KB uploaded
                  </div>
                </div>
              }
              {isUploading && uploadMethod !== 'file' &&
                <Loader size="sm" content={`Processing ${uploadMethod}...`} />
              }
            </Col>
            <Col xs={6} style={{ textAlign: "right" }}>
              <Button onClick={handleUpload} appearance="primary" disabled={isUploading}>Upload</Button>
              <Button onClick={onClose} appearance="subtle" disabled={isUploading}>Cancel</Button>
            </Col>
          </Row>
        </Grid>
      </Modal.Footer>
    </Modal>
  );
}

export default UploadCandidateModal;
